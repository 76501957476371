<template>
  <div class="login-home">
    <nav-bar class="nav-bar" ref="nav"></nav-bar>
    <div class="login-home-filter">

      <div class="filter-item align-center">
        <div class="type">年级分类：</div>
        <div class="flex-acjc">
          <div v-for="(item, index) in gradelist" :key="index" class="type-item cursor-p"
            :class="{ 'active': gradeIndex === index }" @click="gradeClick(item.id, index)">{{ item.gradeName }}</div>
        </div>
      </div>
      <div class="filter-item align-center">
        <div class="type">主题分类：</div>
        <div class="flex-acjc">
          <div v-for="(item, index) in coursetype" :key="index" class="type-item cursor-p"
            :class="{ 'active': typeIndex === index }" @click="typeClick(item.id, index)">{{ item.typeName }}</div>
        </div>
      </div>
    </div>

    <div class="login-home-content">
      <div class="course-list">
        <div @click="courseinfo(item.id)" class="course-item cursor-p" v-for="(item, index) in courselist.list"
          :key="index">
          <img v-if="item.logo" :src='item.logo' class="item-img" />
          <img v-else src="../../assets/img/home/social_banner.png" class="item-img" />
          <div class="item-desc">
            <div class="text-over">{{ item.courseName }}</div>
            <div class="align-center mt-11">
              <img src="../../assets/img/common/book.svg" class="icon" />
              <div class="c9 font-normal text-over flex-1" style="color: orange;">共{{ item.courseHour }}课时</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-acjc mb-39">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size=this.pagesize layout="prev, pager, next, jumper" :total=this.total>
      </el-pagination>
    </div>
    <bottom-bar class="home-bottom" />
  </div>
</template>
<script>
import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import { CourseTypes, GradeList, TeacherPageList } from '@/api/loginhome'
import { getInfo } from '@/api/login'

import NavBar from '../../components/content/navBar/NavBar'
import BottomBar from '../../components/content/bottomBar/BottomBar'

import { getToken, setToken, removeToken } from '@/utils/cookies.js'

export default {
  name: 'LoginHome',
  components: {
    NavBar,
    BottomBar
  },
  data() {
    return {
      typeId: "",//课程类型主键
      gradeId: "",//年级主键
      currentPage: 1,//初始页      
      pagesize: 20,//每页的数据
      coursetype: [],//课程类型列表
      gradelist: [],//年级列表      
      courselist: [],//课程列表
      users: {},//用户信息
      gradeIndex: 0,
      typeIndex: 0,
      total: 0,
      listQuery: {
        pageIndex: this.currentPage,
        pageSize: this.pagesize,
        typeId: this.typeId,
        gradeId: this.gradeId
      }
    }
  },
  created() {
    //用户信息
    this.userInfo();
    //课程类型列表
    this.typeList();
    //年级列表
    this.gradeList();
    //课程列表
    this.onTeacherPageList();
  },
  methods: {
    //用户信息
    userInfo() {
      var token = getToken();
      getInfo(token).then(response => {
        if (response.status == 200) {
          this.users = response.data;
        } else {
		  let hostname = window.location.hostname;
		  hostname = hostname.replace("www", "");
		  setToken('', {
		  	expires: 1,
		  	path: '',
		  	domain: hostname
		  });
			
          MessageBox.alert(response.message, "提示", {
            confirmButtonText: "确定",
            callback: action => {
              removeToken();
              this.$router.push('/login')
            }
          });
        }
        setTimeout(() => {
          this.listLoading = false
        }, 1.5 * 1000)
      })
    },
    //课程类型列表
    typeList() {
      this.listLoading = true
      CourseTypes('').then(response => {
        if (response.status == 200) {
          this.coursetype = response.data;
          this.coursetype.unshift({
            id: '',
            typeName: '全部',
            createTime: '',
            creater: '',
            status: 0
          });
        } else {
          MessageBox.alert(response.message, "错误", {
            confirmButtonText: '确定',
            type: 'error'
          })
        }
        setTimeout(() => {
          this.listLoading = false
        }, 1.5 * 1000)
      })
    },
    //年级列表
    gradeList() {
      this.listLoading = true
      GradeList('').then(response => {
        if (response.status == 200) {
          this.gradelist = response.data;
          this.gradelist.unshift({
            id: '',
            gradeName: '全部',
            createTime: '',
            creater: '',
            status: 0
          });
        } else {
          MessageBox.alert(response.message, "错误", {
            confirmButtonText: '确定',
            type: 'error'
          })
        }
        setTimeout(() => {
          this.listLoading = false
        }, 1.5 * 1000)
      })
    },
    //课程列表
    onTeacherPageList() {
      this.listLoading = true
      this.listQuery.pageIndex = this.currentPage;
      this.listQuery.pageSize = this.pagesize;
      TeacherPageList(this.listQuery).then(response => {
        if (response.status == 200) {
          this.courselist = response.data;
          this.total = response.data.total;
        } else {
          MessageBox.alert(response.message, "错误", {
            confirmButtonText: '确定',
            type: 'error'
          })
        }
        setTimeout(() => {
          this.listLoading = false
        }, 1.5 * 1000)
      })
    },
    //适用年级
    gradeClick(value, index) {
      this.gradeIndex = index;
      this.listQuery.pageIndex = 0;
      this.listQuery.pageSize = 1;
      this.listQuery.gradeId = value;
      this.onTeacherPageList();
    },
    //主题分类
    typeClick(value, index) {
      this.typeIndex = index;
      this.listQuery.pageIndex = 0;
      this.listQuery.pageSize = 1;
      this.listQuery.typeId = value;
      this.typeId = value;
      this.onTeacherPageList();
    },
    //课程详情点击
    courseinfo(id) {
      this.$router.push(
        {
          name: '/courseDetail',
          query: {
            "id": id, 'typeId': this.typeId
          }
          //params: { "id": id, 'typeid': this.typeId }
        });
    },
    //初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
      this.onTeacherPageList();
    },
    //点击第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.onTeacherPageList();
    }
  },
  mounted() {
    this.$refs.nav.mdd = 1;
    localStorage.setItem("mdd", 1);
  }
}
</script>
<style scoped lang="less">
.login-home {
  min-width: 1000px;

  .nav-bar {
    .bar-icon {
      border-radius: 50%;
      vertical-align: -4px;
      margin-right: 7px;
      width: 1.05rem;
      height: 1.05rem;
    }
  }

  .login-home-filter {
    background-color: #fff;
    padding-left: 9.1rem;
    padding-right: 9.1rem;
    padding-top: 8px;
    padding-bottom: 8px;

    .filter-item {
      height: 2.5rem;
      border-bottom: 1px dashed #E7E7E7;
      font-size: 0.5rem;

      &:last-child {
        border-bottom: none;
      }

      .type {
        color: #303133;
        font-weight: 600;
        width: 6.3rem;
        font-size: 15px;
      }

      .type-item {
        margin-right: 1.9rem;
        color: #303133;
        font-size: 14px;
        transition: all 0.1s;
      }

      .type-item:hover {
        transform: scale(1.2);
        background-color: #eaefff;
        color: #576dc6;
        border-radius: 3px;
      }

      .active {
        color: #fff;
        background-color: #3E7EFF;
        padding: 2px 4px;
        border-radius: 3px;
      }
    }
  }

  .login-home-content {
    padding-left: 9.1rem;
    padding-right: 9.1rem;
    padding-top: 2.2rem;
    padding-bottom: 0.8rem;

    .course-list {
      display: flex;
      flex-wrap: wrap;
    }

    .course-item {
      border-radius: 12px;
      background-color: #fff;
      color: #303133;
      font-weight: 600;
      font-size: 0.7rem;
      margin-right: 0.95rem;
      margin-bottom: 18px;
      width: calc((100% - 0.95rem*4) / 4);
      transition: transform 0.5s;
    }

    .course-item:hover {
      transform: translate(0, -10px);
      background: linear-gradient(1turn, #fff, #fff);
      box-shadow: 0 2px 20px 0 hsla(0, 0%, 51.4%, .2)
    }

    &:nth-child(4n) {
      margin-right: 0px;
    }

    .course-item:last-child {
      margin-right: 0px;
    }

    .item-img {
      width: 100%;
      border-radius: 12px 12px 0 0;
    }

    .item-desc {
      padding: 0.6rem 0.6rem 0.7rem;
    }

    .icon {
      width: 1rem;
      height: 0.8rem;
      margin-right: 3px;
    }
  }
}
</style>