import request from "@/utils/request";  //导入封装请求的js文件

//课程分类列表
export function CourseTypes(query) {
	return request({
		url: '/Course/List',
		method: 'get',
		params: query
	})
}

//年级列表
export function GradeList(query) {
	return request({
		url: '/Grade/List',
		method: 'get',
		params: query
	})
}

//课程列表
export function TeacherPageList(data) {
	return request({
		url: '/Course/TeacherPageList',
		method: 'post',
		data
	})
}